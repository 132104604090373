<template>

  <div class="mt-3">
    <div class="recomendation_hompage_cms mb-3 px-5 pt-5 pb-3">
      <div>
        <div v-if="itemId === 106">
          <h2 class="font-weight-bold mb-4">Buku Populer</h2>
        </div>
        <div v-else-if="itemId === 107">
          <h2 class="font-weight-bold mb-4">Majalah Populer</h2>
        </div>
        <div v-else-if="itemId === 103">
          <h2 class="font-weight-bold mb-4">Fiksi yang sering di baca</h2>
        </div>
        <div v-else-if="itemId === 113">
          <h2 class="font-weight-bold mb-4">Pilihan Editor</h2>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-4">
        <button v-b-modal="'my-modal'" class="btn btn-primary mr-2" :disabled="items.length > 99">
          <i class="fa fa-solid fa-plus"></i>
        </button>
        <button class="btn btn-primary" v-if="itemId !== 113" @click="fetchAutomatically(itemId)">Fetch Data
          Automatically</button>
      </div>
      <div v-if="isLoading">
        <div class="text-center text-dark my-2"
          style="height: 600px; background-color:rgb(204 204 204 / 39%); display: flex; border-radius: 5px; justify-content: center; align-items: center;">
          <div style="transform: translate(-50%, -50%);">
            <b-spinner :show='isLoading' class="align-middle" variant="dark" type="grow" label="Loading .."></b-spinner>
            <span v-if="isLoading">Loading ..</span>
          </div>
        </div>
      </div>
      <div class="parent-table" v-else>
        <table class="table custom drag-move " style="cursor: pointer;" v-show="!isLoading">
          <thead>
            <tr>
              <th></th>
              <th>Item Type</th>
              <th>Item Name</th>
              <th>Items Id</th>
              <th></th>
            </tr>
          </thead>
          <draggable element="tbody" :items="items" :move="checkMove" v-bind="dragOptions" :disabled="!enabled"
            ghost-class="ghost" @start="handleDragStart" @end="handleDragEnd($event,items)">
            <tr v-for="(element, index) in items" :key="element.item_id" :data-offer-id="element.offer_id">        
              <td>
                <i class="fa fa-bars" aria-hidden="true"></i>
              </td>
              <td>{{ element.item_type }}</td>
              <td>{{ element.name }}</td>
              <td>{{ element.item_id }}</td>
              
              <td>
                <span @click="removeItem(element, index)">
                  <i class="fa fa-trash text-danger" aria-hidden="true" style="font-size:20px;"></i>
                </span>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
      <div class="d-flex flex-row justify-content-between mt-3">
        <strong v-if="items.length > 0" class="col-sm-auto pl-2 pt-2">{{items.length}} of 100 </strong>
        <button class="btn btn-primary mb-3 ml-2" @click="saveData()"
          :disabled=" this.draggedItem  === null && listDeleteTable.length === 0 && itemsFieldName.length === 0 && !resetFetchbtn"
         >
          Save
        </button>
      </div>
    </div>
    <b-modal id="my-modal" centered :ok-disabled="itemsFieldName == [] || selectedOption === ''" @ok="addRowData()"
      cancel-title="Cancel" @close="handleCancel" @cancel="handleCancel" :no-close-on-backdrop="true" :no-close-on-esc="true">

      <b-form-select class="mb-3" v-model="selectedOption" :options="contentTypeOptions" @change="optionType()">
      </b-form-select>
      <multiselect placeholder="Enter Tittle Item" open-direction="bottom" v-model="itemsFieldName" label="name"
        track-by="name" :options="optionsSearchFieldName" :loading="isNameSearch" :searchable="true"
        :close-on-select="true" :options-limit="10" :multiple="true" @search-change="categoriesFind">
      </multiselect>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
export default {
  name: 'recommendationhomepagedetail',
  components: {
    draggable
  },
  props: {
    category: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    
      itemId: parseInt(this.$route.params.id),
      contentTypeOptions: ['book', 'magazine', 'newspaper'],
      selectedOption: '',
      itemsFieldName: [],
      isNameSearch: false,
      dragging: false,
      enabled: true,
      resetFetchbtn: false,
      dataTable: [],
      offerIdExists:false,
      optionsSearchFieldName: [],
      optionsFieldName: [],
      moveDrag: [],
      addAllItem: [],
      draggedItem: null,
      listDeleteTable: []
    };

  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.content.items,
      successMessage: (state) => state.content.successMessage,
      errorMessage: (state) => state.content.errorMessage,
      isLoading: (state) => state.content.isLoading,
    }),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    }

  },
  created() {
    this.fetchCategoriRecomendation(this.itemId);
    this.selectedOption = this.itemId === 107 ? 'magazine' : 'book';
  },
  mounted() {
    this.fetchCategoriRecomendation(this.itemId);
  },
  methods: {
    ...mapActions("content", ["fetchCategoriRecomendation", "getSearchOffer", "deleteItems","addItemsField", "allUpdateData", "generateLayout", "moveUptable"]),
    ...mapMutations('content', ['deleteItem','addArrayItems','SET_SORT_PRIORITY','SET_ITEMS']),
    fetchAutomatically(ItemId) {
      this.$swal({
        title: "Are you sure?",
        text: "If you fetch data automatically, the data will be reset.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            layout_id: ItemId,
            item_type: this.itemId === 106 ? 'book' : (this.itemId === 107 ? 'magazine' : 'book')
          }
          this.generateLayout(payload)
            .then(() => {
              this.resetFetchbtn = true
            })
            .catch((errors) => {
              console.log('erorr', errors)
              this.messageAlert("error", errors.message);
            });
        }
      });

    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    removeItem(data, index) {
      this.$swal({
        title: "Are you sure?",
        text: "do you want to delete this data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            layout_id: this.itemId,
            offer_id: data.offer_id
          }
          this.listDeleteTable.push(payload)
          this.deleteItem(index);
        }
      });
    },
    categoriesFind(query) {
      if (!query) return;

      clearTimeout(this.debounce)
      const payload = {
        param: this.selectedOption,
        qry: query
      };
      this.isNameSearch = true;
      this.debounce = setTimeout(() => {
        this.getSearchOffer(payload).then((response) => {
          this.optionsSearchFieldName = response.data.data
          this.isNameSearch = false;
        }).catch(() => {
          this.isNameSearch = false;
        });
      }, 600);
    },
    optionType() {
      this.optionsSearchFieldName = []
      this.itemsFieldName = []
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    handleDragStart(event) {
      const offerId = parseInt(event.item.dataset.offerId, 10);
      this.draggedItem = this.items.find(item => item.offer_id === offerId);
    },
  
    handleDragEnd(event) {
      const newIndex = event.newIndex;
      const offerId = parseInt(event.item.dataset.offerId, 10);
      const datadrag = this.items.find(item => item.offer_id === offerId);
      this.SET_SORT_PRIORITY({ datadrag, newIndex });
    },

    async saveData() {
      await this.allUpdateData(this.items)
        .then((response) => {
          if(response.message === "Updated offer successfully") {
            this.messageAlert("success", "Update has been success");
            this.itemsFieldName = []
           this.resetFetchbtn = false
           return response
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    offerIdExistsInTable(offerId) {
      return this.items.some(item => item.item_id === offerId);
    },
    addRowData() {
      const duplicateOfferIds = [];
      this.itemsFieldName.forEach(item => {
          if (this.offerIdExistsInTable(item.item_id)) {
              if (!duplicateOfferIds.includes(item.item_id)) {
                  duplicateOfferIds.push(item.item_id);
              }
          }
      });

      this.offerIdExists = this.itemsFieldName.some(item => this.offerIdExistsInTable(item.item_id));
      if (!this.offerIdExists) {
           const data = []
          this.itemsFieldName = this.itemsFieldName.map(item => ({
            ...item,
            layout_id: this.itemId,
            sort_priority:this.items.length + 1
          }));  
        const payload = {
          offers: this.itemsFieldName
        }     
        data.push(payload) 
        this.addArrayItems(data)
        this.resetFetchbtn = true    
        this.itemsFieldName = []    
      }else {
        this.messageAlert("error", `item id ${duplicateOfferIds} already to on the table`);
      }
   
    },
    handleCancel() {
      this.optionsSearchFieldName = []
      this.itemsFieldName = []
    }
  }
};
</script>
<style>
.custom-arrow {
  cursor: pointer;
}

.recomendation_hompage_cms {
  background-color: #fff;
}

.custom-arrow.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.table.custom.drag-move {
  background-color: #ffffff;
}

table tr {
  background: #ffffff;
}
.table.custom.drag-move thead tr th {
  height: 50px;
}
.list-group {
  background-color: #ffffff;
}

.parent-table {
  max-height: 600px;
  overflow-y: auto;
}

.drag-move th,
.drag-move td {
  padding: 8px 12px;
  text-align: left;
  font-size: 15px;
  vertical-align: middle !important;
  border-bottom: 1px solid #ddd;
}

.drag-move th {
  position: sticky;
  top: 0;
  background-color: #c2cfd6;
  z-index: 2;
}

.drag-move thead th {
  border-bottom: 1px solid #c2cfd6;
}

.drag-move tr:first-child th {
  top: -1px;
  border-top: none;
}

.ghost {
  opacity: 1;
  color: #fff;
  background-color: #0060AF;
}

.dragged-row {
  background-color: #f0f0f0;
  /* Background color for dragged rows */
}
</style>
