var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "recomendation_hompage_cms mb-3 px-5 pt-5 pb-3"
  }, [_c('div', [_vm.itemId === 106 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v("Buku Populer")])]) : _vm.itemId === 107 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v("Majalah Populer")])]) : _vm.itemId === 103 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v("Fiksi yang sering di baca")])]) : _vm.itemId === 113 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v("Pilihan Editor")])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-end mb-4"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal",
      value: 'my-modal',
      expression: "'my-modal'"
    }],
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "disabled": _vm.items.length > 99
    }
  }, [_c('i', {
    staticClass: "fa fa-solid fa-plus"
  })]), _vm.itemId !== 113 ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.fetchAutomatically(_vm.itemId);
      }
    }
  }, [_vm._v("Fetch Data Automatically")]) : _vm._e()]), _vm.isLoading ? _c('div', [_c('div', {
    staticClass: "text-center text-dark my-2",
    staticStyle: {
      "height": "600px",
      "background-color": "rgb(204 204 204 / 39%)",
      "display": "flex",
      "border-radius": "5px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('b-spinner', {
    staticClass: "align-middle",
    attrs: {
      "show": _vm.isLoading,
      "variant": "dark",
      "type": "grow",
      "label": "Loading .."
    }
  }), _vm.isLoading ? _c('span', [_vm._v("Loading ..")]) : _vm._e()], 1)])]) : _c('div', {
    staticClass: "parent-table"
  }, [_c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "table custom drag-move",
    staticStyle: {
      "cursor": "pointer"
    }
  }, [_vm._m(0), _c('draggable', _vm._b({
    attrs: {
      "element": "tbody",
      "items": _vm.items,
      "move": _vm.checkMove,
      "disabled": !_vm.enabled,
      "ghost-class": "ghost"
    },
    on: {
      "start": _vm.handleDragStart,
      "end": function ($event) {
        return _vm.handleDragEnd($event, _vm.items);
      }
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.items, function (element, index) {
    return _c('tr', {
      key: element.item_id,
      attrs: {
        "data-offer-id": element.offer_id
      }
    }, [_c('td', [_c('i', {
      staticClass: "fa fa-bars",
      attrs: {
        "aria-hidden": "true"
      }
    })]), _c('td', [_vm._v(_vm._s(element.item_type))]), _c('td', [_vm._v(_vm._s(element.name))]), _c('td', [_vm._v(_vm._s(element.item_id))]), _c('td', [_c('span', {
      on: {
        "click": function ($event) {
          return _vm.removeItem(element, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "font-size": "20px"
      },
      attrs: {
        "aria-hidden": "true"
      }
    })])])]);
  }), 0)], 1)]), _c('div', {
    staticClass: "d-flex flex-row justify-content-between mt-3"
  }, [_vm.items.length > 0 ? _c('strong', {
    staticClass: "col-sm-auto pl-2 pt-2"
  }, [_vm._v(_vm._s(_vm.items.length) + " of 100 ")]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary mb-3 ml-2",
    attrs: {
      "disabled": this.draggedItem === null && _vm.listDeleteTable.length === 0 && _vm.itemsFieldName.length === 0 && !_vm.resetFetchbtn
    },
    on: {
      "click": function ($event) {
        return _vm.saveData();
      }
    }
  }, [_vm._v(" Save ")])])]), _c('b-modal', {
    attrs: {
      "id": "my-modal",
      "centered": "",
      "ok-disabled": _vm.itemsFieldName == [] || _vm.selectedOption === '',
      "cancel-title": "Cancel",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    on: {
      "ok": function ($event) {
        return _vm.addRowData();
      },
      "close": _vm.handleCancel,
      "cancel": _vm.handleCancel
    }
  }, [_c('b-form-select', {
    staticClass: "mb-3",
    attrs: {
      "options": _vm.contentTypeOptions
    },
    on: {
      "change": function ($event) {
        return _vm.optionType();
      }
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }), _c('multiselect', {
    attrs: {
      "placeholder": "Enter Tittle Item",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "options": _vm.optionsSearchFieldName,
      "loading": _vm.isNameSearch,
      "searchable": true,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true
    },
    on: {
      "search-change": _vm.categoriesFind
    },
    model: {
      value: _vm.itemsFieldName,
      callback: function ($$v) {
        _vm.itemsFieldName = $$v;
      },
      expression: "itemsFieldName"
    }
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("Item Type")]), _c('th', [_vm._v("Item Name")]), _c('th', [_vm._v("Items Id")]), _c('th')])]);
}]

export { render, staticRenderFns }